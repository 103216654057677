.infrastructure-one {
  .infrastructure {
    &__icon-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: var(--turquoise-color);
      opacity: 0.4;
    }

    &__place {
      display: flex;
    }

    &__places {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }

    &__title-wrap {
      display: flex;
      align-items: flex-start;
    }

    &__grid-wrap {
      grid-row-gap: 0;
    }

    &__text {
      align-self: center;
    }
  }
}

@include respond-up('x-large') {
  .infrastructure-one {
    .infrastructure {
      &__places {
        margin-top: 30px;
      }
    }
  }
}

@include respond-up('large') {
  .infrastructure-one {
    .infrastructure {
      &__infrastructure-wrap {
        margin-top: 60px;
      }
    }
  }
}

@include respond-up('s-large') {
  .infrastructure-one {
    .infrastructure {
      &__icon-wrap {
        width: 52px;
        height: 52px;
        flex: 0 0 52px;
      }

      &__text {
        margin-left: 20px;
      }

      &__infrastructure-wrap {
        &_walk {
          grid-column: 1/6;
        }

        &_bus {
          grid-column: 7/12;
        }
      }

      &__place {
        justify-content: space-between;
        align-items: flex-end;
        flex-direction: row;
      }

      &__place-name {
        flex: 0 0 auto;
        max-width: var(--grid-column4);
      }

      &__distance-time-wrap {
        margin-left: 10px;
        display: flex;
        white-space: nowrap;
      }

      &__dots {
        width: 100%;
        margin-left: 10px;
        border-bottom: 1px dashed var(--color-grey-02);
        margin-bottom: 8px;
      }

      &__places {
        row-gap: 12px;
      }
    }
  }
}

@include respond('s-large') {
  .infrastructure-one {
    .infrastructure {
      &__infrastructure-wrap {
        margin-top: 50px;
      }
    }
  }
}

@include respond-up('medium') {
  .infrastructure-one {
    .infrastructure {

    }
  }
}

@include respond('medium') {
  .infrastructure-one {
    .infrastructure {
      &__infrastructure-wrap {
        &_walk {
          grid-column: 1/4;
        }

        &_bus {
          grid-column: 4/7;
        }
      }

      &__places {
        row-gap: 20px;
      }
    }
  }
}

@include respond-down('medium') {
  .infrastructure-one {
    .infrastructure {
      &__icon-wrap {
        width: 42px;
        height: 42px;
        flex: 0 0 42px;

        svg {
          width: 26px;
          height: 26px;
        }
      }

      &__text {
        margin-left: 10px;
      }

      &__infrastructure-wrap {
        margin-top: 40px;
      }

      &__dots {
        display: none;
      }

      &__place {
        flex-direction: column;
      }
    }
  }
}

@include respond-down('small') {
  .infrastructure-one {
    .infrastructure {
      &__infrastructure-wrap {
        &_walk {
          grid-column: 1/5;
          grid-row: 1/2;
        }

        &_bus {
          grid-column: 1/5;
          grid-row: 2/2;
        }
      }
    }
  }
}