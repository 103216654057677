.index-page {
  &__blocks {
    display: flex;
    flex-direction: column;
  }

  &__block {

  }
}

@include respond-up('x-large') {
  .index-page {
    &__block {
      padding-top: 180px;
    }
  }
}

@include respond-up('large') {
  .index-page {
    &__blocks {
      padding-bottom: 120px;
    }

    &__mortgage {
      margin-top: 63px;
    }
  }
}

@include respond('large') {
  .index-page {
    &__block {
      padding-top: 120px;
    }
  }
}

@include respond('s-large') {
  .index-page {
    &__blocks {
      padding-bottom: 100px;
    }

    &__block {
      padding-top: 100px;
    }

    &__mortgage {
      margin-top: 80px;
    }
  }
}

@include respond('medium') {
  .index-page {
    &__block {
      padding-top: 80px;
    }

    &__mortgage {
      margin-top: 60px;
    }

    &__blocks {
      padding-bottom: 80px;
    }
  }
}

@include respond-down('small') {
  .index-page {
    &__block {
      padding-top: 50px;
    }

    &__mortgage {
      margin-top: 30px;
    }

    &__blocks {
      padding-bottom: 60px;
    }
  }
}