.tabs {
  //overflow-x: scroll;
  //margin: 0 calc(var(--grid-spacer) * -1);

  &::-webkit-scrollbar {
    display: none;
  }

  &__text {
    white-space: nowrap;
  }

  &__list {
    display: flex;
  }

  &__link {
    display: flex;
    justify-content: center;
  }

  &__vertical {
    .tabs {
      &__text {
        font-family: var(--font);
        font-weight: 600;
        line-height: 160%;
        color: var(--color-grey-03);
        transition: color var(--default-timing) var(--default-transition-function);
      }

      &__item {
        position: relative;

        &:before {
          position: absolute;
          content: '';
          background: var(--turquoise-color);
          left: 0;
          opacity: 0;
          transition: opacity var(--default-timing) var(--default-transition-function);
        }

        &._active {
          &:before {
            opacity: 1;
          }

          .tabs {
            &__text {
              color: var(--turquoise-color);
            }
          }
        }
      }
    }
  }

  &__major,
  &__minor {
    .tabs {
      &__list {
        background-color: var(--tabs-list-background);
        color: var(--color-grey-03);
        width: fit-content;
        padding: var(--tabs-list-padding);
        border-radius: var(--input-border-radius);
      }

      &__item {
        transition: color var(--default-timing) var(--default-transition-function), background-color var(--default-timing) var(--default-transition-function), border-color var(--default-timing) var(--default-transition-function);
        border: 1px solid var(--tabs-border-color);
        border-radius: var(--input-border-radius);

        &:not(:last-child) {
          margin-right: 4px;
        }

        &._active {
          background: var(--tabs-active-background);
          color: var(--tabs-active-color);
          border-color: var(--tabs-active-border-color);

          &:hover {
            color: var(--tabs-active-color);
          }
        }

        &:hover {
          color: var(--tabs-hover-color);
        }
      }

      &__link {
        padding: var(--tabs-item-padding);
      }
    }
  }

  &__major {
  }

  &__minor {
  }
}

// vertical
@include respond-up('s-large') {
  .tabs {
    &__vertical {
      .tabs {
        &__text {
          white-space: normal;
        }

        &__list {
          flex-direction: column;
        }

        &__link {
          padding: 10px 0 10px 10px;
          margin-left: -10px;
          transition: transform var(--default-timing) var(--default-transition-function);
        }

        &__item {
          &:before {
            width: 2px;
            height: 18px;
            bottom: 15px;
          }

          &:first-child {
            .tabs {
              &__link {
                margin-top: -10px;
              }
            }
          }

          &:last-child {
            &:before {
              bottom: 5px;
            }

            .tabs {
              &__link {
                margin-bottom: -10px;
              }
            }
          }

          &._active {
            .tabs {

              &__link {
                transform: translateX(10px);
              }
            }
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .tabs {
    &__vertical {
      .tabs {
        &__text {
          font-size: 17px;
        }
      }
    }
  }
}

@include respond('medium') {
  .tabs {
    &__vertical {
      .tabs {
        &__link {
          padding: 10px 12px;
          margin-top: -10px;
        }

        &__item {
          &:before {
            width: calc(100% - 24px);
            left: 12px;
          }

          &:first-child {
            &:before {
              width: calc(100% - 12px);
              left: 0;
            }

            .tabs {
              &__link {
                margin-left: -12px;
              }
            }
          }

          &:last-child {
            &:before {
              width: calc(100% - 12px);
              right: 0;
            }

            .tabs {
              &__link {
                margin-right: -12px;
              }
            }
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .tabs {
    &__vertical {
      padding-left: var(--grid-spacer);
      padding-right: var(--grid-spacer);
      margin-left: calc(0px - var(--grid-spacer));
      margin-right: calc(0px - var(--grid-spacer));
      overflow: scroll hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      .tabs {
        &__item {
          border-bottom: 1px solid var(--color-grey-02);
        }

        &__item {
          &:before {
            height: 2px;
            bottom: -1px;
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .tabs {
    &__vertical {
      .tabs {
        &__text {
          font-size: 16px;
        }

        &__link {
          padding: 10px 10px;
          margin-top: -10px;
        }

        &__item {
          &:before {
            width: calc(100% - 20px);
            left: 10px;
          }

          &:first-child {
            &:before {
              width: calc(100% - 10px);
              left: 0;
            }

            .tabs {
              &__link {
                margin-left: -10px;
              }
            }
          }

          &:last-child {
            &:before {
              width: calc(100% - 10px);
              right: 0;
            }

            .tabs {
              &__link {
                margin-right: -10px;
              }
            }
          }
        }
      }
    }
  }
}

// major

@include respond-up('s-large') {
  .ui {
    &_four {
      .tabs {
        &__major {
          .tabs {
            &__item {
              &:not(:last-child) {
                margin-right: 6px;
              }
            }
          }
        }
      }
    }
  }

  .tabs {
    &__major {
      --tabs-list-padding: 8px;
      --tabs-item-padding: 4px 13px;
    }
  }
}

@include respond-up('medium') {
  .tabs {
    &__major {
    }
  }
}

@include respond('medium') {
  .tabs {
    &__major {
    }
  }
}

@include respond-down('medium') {
  .tabs {
    &__major {
      --tabs-list-padding: 6px;
      --tabs-item-padding: 5px 14px;
    }
  }
}

@include respond-down('small') {
  .tabs {
    &__major {
    }
  }
}

// minor

@include respond-up('s-large') {
  .ui {
    &_four {
      .tabs {
        &__minor {
          .tabs {
            &__item {
              &:not(:last-child) {
                margin-right: 6px;
              }
            }
          }
        }
      }
    }
  }

  .tabs {
    &__minor {
      --tabs-list-padding: 6px;
      --tabs-item-padding: 3px 10px;
    }
  }
}

@include respond-up('medium') {
  .tabs {
    &__minor {
    }
  }
}

@include respond('medium') {
  .tabs {
    &__minor {
    }
  }
}

@include respond-down('medium') {
  .tabs {
    &__minor {
      --tabs-list-padding: 4px;
      --tabs-item-padding: 5px 8px;
    }
  }
}

@include respond-down('small') {
  .tabs {
    &__minor {
    }
  }
}

