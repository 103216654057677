@include respond-up('s-large') {
  .cursor-slider-arrows {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;

    &__desk-arrow {
      display: flex;
      height: 100%;
      flex: auto;
    }
  }
}

@include respond-down('medium') {
  .cursor-slider-arrows {
    display: none;
  }
}