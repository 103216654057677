.route-object {
  &:not(&_placemark) {
    &.genplan {
      &__object-placemark {
        display: flex;
        width: 18px;
        height: 18px;
        background: #2F96A2;
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
        transition: background var(--default-timing) var(--default-bezier);
        z-index: 10;

        &::before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: var(--color-main-01);
          opacity: 0;
          z-index: 5;
        }

        &._active {
          background: var(--color-main-01);

          &::before {
            animation: pulse 1.5s infinite;
          }
        }
      }
    }
  }

  &_placemark {
    &.genplan {
      &__object-placemark {
        display: flex;
        width: 35px;
        height: 41px;
        pointer-events: none;
        transform: translate3d(-50%, -80%, 0);
      }
    }
  }

  &__placemark {
    display: flex;
    width: 100%;
    height: 100%;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    &.genplan__object-balloon._popover {
      background-color: unset;
      opacity: 1;
      visibility: visible;
      z-index: unset;
      padding: 0;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      pointer-events: unset;
    }
  }

  &__name {
    color: #fff;
    text-wrap: nowrap;
  }

  &__time {
    color: #FFFFFF80;
  }
}

@include respond-up('s-large') {
  .route-object {
    &:not(&_placemark) {
      &.genplan {
        &__object-placemark {
          &:hover {
            background: var(--color-main-01);
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .route-object {

  }
}

@include respond('medium') {
  .route-object {

  }
}

@include respond-down('medium') {
  .route-object {

  }
}

@include respond-down('small') {
  .route-object {

  }
}

@keyframes pulse {
  0% {
    opacity: .5;
    transform: scale(1);
  }
  100% {
    opacity: .0;
    transform: scale(2);
  }
}