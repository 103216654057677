/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$grid: (
  small: (
    from: 0,
    to: 767
  ),
  medium: (
    from: 768,
    to: 1199
  ),
  s-large: (
    from: 1200,
    to: 1599
  ),
  large: (
    from: 1600,
    to: 2500
  ),
  x-large: (
    from: 2501
  )
);

:root {
  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "Nunito Sans", sans-serif;
  --h-font: "Jost", sans-serif;

  --t: transparent;

  --turquoise-color: rgba(47, 150, 162, 1);
  --turquoise-color-hover-80: rgba(47, 150, 162, 0.8);
  --turquoise-color-hover-40: rgba(47, 150, 162, 0.4);
  --grey-color: #7A7A7A;
  --primary-color: black;
  --background-color: #f0f0f0; // серая подлога
  --alert-color: #FF6969;
  --default-transition: all 0.4s var(--default-transition-function);
  --default-transition-function: ease-in-out;
  --primary-color-opacity-40: rgba(0, 0, 0, .4);
  --primary-color-opacity: rgba(0, 0, 0, .85);

  --default-timing: .4s;
  --medium-timing: .6s;
  --long-timing: .8s;
  --teaser-transition-func: cubic-bezier(.25, .1, .25, 1);
  --default-bezier: cubic-bezier(0.25, 0.1, 0.25, 1);
}