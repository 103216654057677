.button, button {
  display: inline-block;
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  color: var(--button-text-color);
  background: var(--turquoise-color);
  border-radius: var(--button-border-radius);
  border: 1px solid var(--turquoise-color);
  font-family: var(--font);
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-size: var(--button-font-size);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ui {
  &_four {
    .button, button {
      &_hero {
        color: var(--color-add-04);
        border-color: var(--color-add-04);
      }
    }
  }
}

@include respond-up('x-large') {
  .button, button {
    height: 62px;
    padding: 18px 24px;
  }
}

@include respond-between('s-large', 'large') {
  .button, button {
    height: 52px;
    padding: 16px 24px;
  }
}

@include respond-up('s-large') {
  .ui {
    &_four {
      .button, button {
        &_hero {
          &:hover {
            background-color: var(--color-main-01);
            border-color: var(--color-main-01);
            color: var(--color-add-04);
          }
        }
      }
    }
  }

  .button, button {
    &:hover {
      background-color: var(--turquoise-color-hover-80);
      border-color: var(--turquoise-color-hover-80);
    }
  }
}

@include respond-down('medium') {
  .button, button {
    height: 42px;
    padding: 12px 24px;
  }
}

.ui {
  &_four {
    .button, button {
      text-transform: none;
    }
  }
}