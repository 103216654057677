.gen-building {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity 0.3s;

  &._firstScreen {
    position: relative;
  }
  &._hidden {
    opacity: 0;
    z-index: -1;
  }

  &__wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__tabs {
    position: absolute;
    z-index: 100;
    left: var(--grid-spacer-and-indent);

    &._hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__tabs-list {
    &.tabs__list {
      width: 100%;
    }
  }

  &__tabs-item {
    flex: 0 1 50%;

    &.tabs__item {
      margin-right: 0;
    }
  }

  &__chess {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@include respond-up('x-large') {
  .gen-building {
    height: 1755px;

    &__plan,
    &__chess {
      height: 1755px;
    }
  }
}

@include respond-up('large') {
  .gen-building {
    &__tabs {
      bottom: 60px;
    }
  }
}

@include respond('large') {
  .gen-building {
    height: 880px;

    &__plan,
    &__chess {
      height: 880px;
    }
  }
}

@include respond-up('s-large') {
  .gen-building {
    &__tabs {
      width: var(--grid-column3);
    }
  }
}

@include respond('s-large') {
  .gen-building {
    height: 710px;

    &__tabs {
      bottom: 40px;
    }

    &__plan,
    &__chess {
      height: 710px;
    }
  }
}

@include respond-up('medium') {
  .gen-building {

  }
}

@include respond('medium') {
  .gen-building {
    height: 680px;

    &__tabs {
      bottom: 30px;
      width: var(--grid-column2);
    }

    &__plan,
    &__chess {
      height: 680px;
    }
  }
}

@include respond-down('medium') {
  .gen-building {

  }
}

@include respond-down('small') {
  .gen-building {
    height: 600px;

    &__tabs {
      bottom: 20px;
      width: var(--grid-column4);
    }

    &__plan,
    &__chess {
      height: 600px;
    }
  }
}