.text {
  // colors

  &__black-text {
    color: var(--color-add-01);
  }

  &__white-text {
    color: var(--color-add-04);
  }

  &__grey03 {
    color: var(--color-grey-03);
  }

  &__main01 {
    color: var(--color-main-01);
  }

  // styles

  &__main {
    @extend %text-main;
  }

  &__medium {
    @extend %text-medium;
  }

  &__body {
    @extend %text-body;
  }

  &__mini {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 155%;
  }

  &__small {
    font-family: var(--font);
    font-style: normal;
    font-weight: 400;
  }

  // TODO: rewrite
  &__error-page {
    font-family: var(--font);
    font-size: var(--font-size);
    font-style: normal;
    color: var(--primary-color);
    font-variation-settings: var(--font-settings), 'opsz' 60;
    font-stretch: 151%;
    font-weight: 600;
    line-height: 120%;
  }
}

@include respond-up("x-large") {
  .text {
    &__small {
      font-size: 19px;
    }

    &__mini {
      font-size: 16px;
      line-height: 150%;
    }
  }
}

@include respond-between("s-large", "large") {
  .text {
    &__small {
      font-size: 14px;
    }
  }
}

@include respond-up("s-large") {
  .text {
    &__small {
      line-height: 155%;
    }
  }
}

@include respond-up("medium") {
  .text {
    &__error-page {
      --font-size: 200px;
    }
  }
}

@include respond-down("medium") {
  .text {
    &__small {
      font-size: 13px;
      line-height: 150%;
    }
  }
}

@include respond-down("small") {
  .text {
    &__error-page {
      --font-size: 80px;
    }
  }
}
