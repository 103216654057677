.play-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--turquoise-color);
  border-radius: 100%;
  padding-left: 2px;
}

@include respond-up('x-large') {
  .play-icon {
    width: 66px;
    height: 66px;
  }
}

@include respond-between('s-large', 'large') {
  .play-icon {
    width: 52px;
    height: 52px;
  }
}

@include respond-down('medium') {
  .play-icon {
    width: 44px;
    height: 44px;

    svg {
      width: 9px;
      height: 11px;
    }
  }
}