:root {
  --errors-color: var(--alert-color, #b50000)
}

$inputs: 'input[type="text"], input[type="search"], input[type="password"], input[type="number"]';

form {
  #{$inputs}, textarea, select {
    font-style: normal;
    font-weight: 400;
    color: var(--color-add-05);
    width: 100%;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px var(--input-background-color) inset;
      -webkit-text-fill-color: var(--color-add-05);
      transition: color .4s, text-fill-color .4s, box-shadow .4s !important;
      transition-timing-function: var(--default-transition-function);
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  label {
    display: none;
  }

  ul.errors {
    position: absolute;
    list-style: none;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: var(--color-add-06);

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    &._errors {
      #{$inputs} {
        border: 1px solid var(--color-add-06);
      }
    }
  }

  #{$inputs}, textarea {
    padding: var(--input-padding);

    &::placeholder {
      color: var(--color-grey-03);
    }

    &:focus::placeholder {
      color: var(--t);
    }
  }

  #{$inputs}, select {
    background-color: var(--input-background-color);
    height: var(--default-input-height);
    border-radius: var(--input-border-radius);
    border: var(--input-border);
  }

  textarea {
    padding: 5px 10px;
  }

  //.button, button {
  //  padding: 0;
  //  margin: 0;
  //  text-align: center;
  //  transition: var(--default-transition);
  //  border: none;
  //  background: black;
  //  font-size: 16px;
  //  cursor: pointer;
  //  text-decoration: none;
  //  border-radius: 100px;
  //  color: #fff;
  //  font-weight: 500;
  //  line-height: 110%;
  //  text-transform: uppercase;
  //  font-stretch: 151%;
  //  font-variation-settings: var(--font-settings);
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //}
}

@include respond-up('x-large') {
  :root {
    --default-input-height: 62px;
  }

  form {
    #{$inputs} {
      font-size: 24px;
    }
  }
}

@include respond-between('s-large', 'large') {
  :root {
    --default-input-height: 52px;
  }

  form {
    #{$inputs} {
      font-size: 17px;
    }
  }
}

@include respond-up('s-large') {
  form {
    #{$inputs} {
      line-height: 160%;
    }

    ul.errors {
      margin-top: 4px;
    }
  }
}

@include respond-down('medium') {
  :root {
    --default-input-height: 42px;
  }

  form {
    #{$inputs} {
      font-size: 16px;
      line-height: 130%;
    }
  }
}