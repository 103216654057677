.about-ornament {
  display: flex;
  align-items: center;

  &_blue {
    .about-ornament {
      &__line {
        &::before {
          border-color: #2F96A2;
        }
      }
    }
  }

  &_red {
    .about-ornament {
      &__line {
        &::before {
          border-color: #CF3D24;
        }
      }
    }
  }

  &_orange {
    .about-ornament {
      &__line {
        &::before {
          border-color: #F58220;
        }
      }
    }
  }

  &__line {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      border-bottom: 1px solid;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('x-large') {
  .about-ornament {
    column-gap: 50px;
    padding-bottom: 80px;
    &_blue {
      .about-ornament {
        &__icon {
          flex: 0 0 297px;
          height: 67px;
        }
      }
    }

    &_red {
      .about-ornament {
        &__icon {
          flex: 0 0 254px;
          height: 86px;
        }
      }
    }

    &_orange {
      .about-ornament {
        &__icon {
          flex: 0 0 230px;
          height: 118px;
        }
      }
    }
  }
}

@include respond-between('s-large', 'large') {
  .about-ornament {
    padding-bottom: 50px;
  }
}

@include respond-between('medium', 'large') {
  .about-ornament {
    column-gap: 30px;
    &_blue {
      .about-ornament {
        &__icon {
          flex: 0 0 145px;
          height: 33px;
        }
      }
    }

    &_red {
      .about-ornament {
        &__icon {
          flex: 0 0 124px;
          height: 42px;
        }
      }
    }

    &_orange {
      .about-ornament {
        &__icon {
          flex: 0 0 111px;
          height: 58px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .about-ornament {

  }
}

@include respond('medium') {
  .about-ornament {
    padding-bottom: 40px;
  }
}

@include respond-down('medium') {
  .about-ornament {

  }
}

@include respond-down('small') {
  .about-ornament {
    padding-bottom: 20px;
    column-gap: 16px;
    &_blue {
      .about-ornament {
        &__icon {
          flex: 0 0 117px;
          height: 26px;
        }
      }
    }

    &_red {
      .about-ornament {
        &__icon {
          flex: 0 0 95px;
          height: 32px;
        }
      }
    }

    &_orange {
      .about-ornament {
        &__icon {
          flex: 0 0 89px;
          height: 46px;
        }
      }
    }
  }
}