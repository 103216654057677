.genplan-infrastructure {
  &__text-wrap {
    position: absolute;
    left: var(--grid-spacer-and-indent);
    display: flex;
    flex-direction: column;
    z-index: 110;
    pointer-events: none;
  }

  &__name,
  &__description {
    color: #fff;
  }

  &__description {
    font-family: var(--h-font);
    font-weight: 600;
    line-height: 130%;
    text-transform: uppercase;
  }

  &__color-text {
    color: var(--color-main-01);
  }

  &__placemark {
    display: flex;
    background: var(--color-main-01);
    color: #fff;
    padding: 1px 5px;
    opacity: 0;
    transition: opacity var(--default-timing) var(--default-bezier);
    pointer-events: none;

    &._active {
      opacity: 1;
      transition-delay: 1.5s;
    }
  }

  &__placemark-text {
    text-wrap: nowrap;
  }

  .genplan__layer-polygon {
    svg {
      polyline {
        stroke-dasharray: 200%;
        stroke-dashoffset: 200%;

        &._active {
          animation: route 10s linear forwards;
        }
      }
    }
  }

  .genplan-block {
    &__main {
      margin-top: 0;
    }
  }
}

@include respond-up('x-large') {
  .genplan-infrastructure {
    &__genplan {
      height: 907px;
    }

    &__text-wrap {
      top: calc(202/3200 * 100vw);
    }

    &__description {
      font-size: 42px;
    }
  }
}

@include respond-between('s-large', 'large') {
  .genplan-infrastructure {
    &__genplan {
      height: 743px;
    }

    &__text-wrap {
      top: calc(206/1600 * 100vw);
    }

    &__description {
      font-size: 28px;
    }
  }
}

@include respond-up('s-large') {
  .genplan-infrastructure {
    &__main {
      position: relative;
    }

    &__text-wrap {
      width: var(--grid-column3);
    }

    &__description {
      margin-top: 30px;
    }
  }
}

@include respond-up('medium') {
  .genplan-infrastructure {

  }
}

@include respond('medium') {
  .genplan-infrastructure {
    &__genplan {
      height: 764px;
    }

    &__text-wrap {
      width: var(--grid-column5);
    }

    &__description {
      margin-top: 20px;
      font-size: 25px;
    }
  }
}

@include respond-down('medium') {
  .genplan-infrastructure {
    &__text-wrap {
      top: 50px;
    }
  }
}

@include respond-down('small') {
  .genplan-infrastructure {
    &__genplan {
      height: 723px;
    }

    &__text-wrap {
      width: var(--grid-column4);
    }

    &__description {
      margin-top: 14px;
      line-height: 140%;
      font-size: 18px;
    }
  }
}

@keyframes route {
  to {
    stroke-dashoffset: 0;
  }
}