.infrastructure {
  &__wrapper {
    background-color: var(--background-footer-color);
    overflow: hidden;
  }
}

@include respond-up('large') {
  .infrastructure {
    padding-bottom: 80px;
  }
}

@include respond-up('s-large') {
  .infrastructure {

  }
}

@include respond('s-large') {
  .infrastructure {
    padding-bottom: 60px;
  }
}

@include respond-up('medium') {
  .infrastructure {

  }
}

@include respond('medium') {
  .infrastructure {

  }
}

@include respond-down('medium') {
  .infrastructure {
    padding-bottom: 40px;
  }
}

@include respond-down('small') {
  .infrastructure {

  }
}