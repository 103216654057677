.mortgage {
  &__inner {
    position: relative;
  }

  &__content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(92.89deg, var(--color-grey-01) 11.85%, var(--color-grey-01-40) 112.5%);
  }

  &__picture-wrap {
    position: absolute;
    bottom: 0;
    max-height: 100%;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__subtitle {
    font-style: normal;
  }
}

@include respond-up('x-large') {
  .mortgage {
    &__inner {
      height: calc((var(--grid-column3) + var(--grid-gap) + 35px) * (515 / 724) + 40px);
    }

    &__content {
      height: 100%;
    }

    &__subtitle {
      font-weight: 400;
      line-height: 160%;
    }

    &__button {
      width: fit-content;
    }

    &__picture-wrap {
      width: calc(var(--grid-column3) + var(--grid-gap) + 35px);
      height: calc((var(--grid-column3) + var(--grid-gap) + 35px) * (515 / 724));
      right: 203px;
    }
  }
}

@include respond-up('large') {
  .mortgage {
    &__inner {
      max-width: var(--grid-column11);
    }

    &__subtitle {
      margin-top: 10px;
    }

    &__button {
      margin-top: 40px;
    }

    &__main {
      width: var(--grid-column6);
      padding: 40px 0 40px 60px;
    }
  }
}

@include respond('large') {
  .mortgage {
    &__inner {
      padding-top: 41px;
    }

    &__content {
      min-height: 404px;
    }

    &__picture-wrap {
      width: calc(var(--grid-column4) - 30px);
      height: calc((var(--grid-column4) - 30px) * (445 / 430));
      right: 60px;
    }
  }
}

@include respond-between('s-large', 'large') {
  .mortgage {
    &__button {
      max-width: 268px;
    }
  }
}

@include respond-up('s-large') {
  .mortgage {
    &__subtitle {
      font-size: 24px;
    }
  }
}

@include respond('s-large') {
  .mortgage {
    &__inner {
      padding-top: 44px;
    }

    &__content {
      min-height: 329px;
    }

    &__main {
      width: calc(var(--grid-column7) + var(--grid-gap));
      padding: 40px 0 40px 50px;
    }

    &__subtitle {
      margin-top: 8px;
    }

    &__button {
      margin-top: 47px;
    }

    &__picture-wrap {
      width: calc(var(--grid-column4) - 20px);
      height: calc((var(--grid-column4) - 20px) * (373 / 360));
      right: 50px;
    }
  }
}

@include respond-between('medium', 'large') {
  .mortgage {
    &__subtitle {
      font-weight: 600;
      line-height: 155%;
    }
  }
}

@include respond('medium') {
  .mortgage {
    &__content {
      min-height: 362px;
    }

    &__main {
      width: var(--grid-column3);
      padding: 30px 0 30px 40px;
    }

    &__subtitle {
      font-size: 18px;
      margin-top: 8px;
    }

    &__picture-wrap {
      right: 30px;
      width: calc(var(--grid-column3) - 30px);
      height: calc((var(--grid-column3) - 30px) * (315 / 304));
    }

    &__button {
      max-width: 241px;
    }
  }
}

@include respond-down('medium') {
  .mortgage {
    &__button {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .mortgage {
    &__main {
      padding: 20px 20px 30px;
    }

    &__subtitle {
      margin-top: 6px;
      font-weight: 400;
      font-size: 16px;
      line-height: 160%;
    }

    &__picture-wrap {
      display: none;
    }
  }
}