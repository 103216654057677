.pattern-block {
  position: relative;

  &__pattern-wrap {
    position: absolute;
    pointer-events: none;

    &_top {
      top: 0;
    }

    &_bottom {
      bottom: 0;
    }

    &_left {
      left: 0;
    }

    &_right {
      right: 0;
    }

    &.pattern-block__pattern-wrap_bottom.pattern-block__pattern-wrap_right {
      .pattern-block {
        &__pattern-big {
          transform: scale(-1, 1);
        }
      }
    }
  }

  &__pattern {
    display: flex;
    align-items: center;
    justify-content: center;

    &_grey {
      svg {
        g path {
          fill: #EEEEF0;
        }
      }
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include respond-up('x-large') {
  .pattern-block {
    &__pattern-big {
      width: 535px;
      height: 535px;
    }

    &__pattern-small {
      width: 535px;
      height: 174px;
    }

    &__pattern-mini {
      width: 361px;
      height: 140px;
    }
  }
}

@include respond-between('s-large', 'large') {
  .pattern-block {
    &__pattern-big {
      width: 327px;
      height: 327px;
    }

    &__pattern-small {
      width: 327px;
      height: 106px;
    }

    &__pattern-mini {
      width: 221px;
      height: 86px;
    }
  }
}

@include respond-up('s-large') {
  .pattern-block {
    &__pattern {
      &_mob {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .pattern-block {
    &__pattern-small {
      width: 264px;
      height: 85px;
    }

    &__pattern-mini {
      width: 178px;
      height: 69px;
    }
  }
}

@include respond-down('medium') {
  .pattern-block {
    &__pattern-wrap {
      &.pattern-block__pattern-wrap_bottom.pattern-block__pattern-wrap_bottom {
        .pattern-block {
          &__pattern-small {
            transform: rotate(180deg);
          }
        }
      }
    }

    &__pattern {
      &_desk {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .pattern-block {
    &__pattern-small {
      width: 181px;
      height: 58px;
    }

    &__pattern-mini {
      width: 122px;
      height: 47px;
    }
  }
}