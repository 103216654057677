.link {
  --link-button-color: var(--turquoise-color);

  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--turquoise-color);
  font-size: var(--button-font-size);
  display: flex;
  width: fit-content;
  transition: color var(--default-timing) var(--default-bezier);

  &__inner {
    display: flex;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background: var(--turquoise-color);
      transition: background var(--default-timing) var(--default-bezier);
    }
  }

  &__title-wrap {
    display: block;
    position: relative;
    overflow: hidden;
  }

  &__title {
    display: block;
    transition: transform var(--default-timing) var(--default-bezier);

    &_duplicate {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate3d(0, 100%, 0);
    }
  }

  &__arrow {
    display: none;

    svg path {
      stroke: var(--link-button-color);
    }
  }

  &_light {
    --link-button-color: var(--color-add-04);
  }

  &_accent {
    --link-button-color: var(--color-main-01);
  }
}

.ui {
  &_one {
    .link {
      &__title {
        &_duplicate {
          display: block;
        }
      }
    }
  }

  &_two {
    .link__inner {
      &::before {
        transform-origin: center right;
        transition: transform .2s ease-out;
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        background: var(--turquoise-color);
        transform: scaleX(0);
        transform-origin: left center;
        transition: transform .2s ease-out;
      }
    }
  }

  &_three {
    .link {
      text-transform: none;

      &__inner {
        padding-bottom: 1px;
      }
    }
  }

  &_four {
    .link {
      &__inner {
        padding-bottom: 1px;
      }

      &__arrow {
        display: block;
        margin-left: 4px;
      }
    }
  }
}

@include respond-up('s-large') {
  .ui {
    &_one {
      .link {
        &:hover {
          .link {
            &__title {
              transform: translate3d(0, -100%, 0);

              &_duplicate {
                transform: translate3d(0, 0, 0);
              }
            }
          }
        }

        &__inner {
          padding-bottom: 5px;
        }
      }
    }

    &_two {
      .link {
        &:hover {
          .link__inner {
            &::before {
              transition: transform 0.3s var(--default-bezier);
              transform: scaleX(0);
            }

            &:after {
              transition: transform 0.3s var(--default-bezier) 0.33s;
              transform: scaleX(1);
            }
          }
        }

        &__inner {
          padding-bottom: 3px;
        }
      }
    }

    &_three {
      .link {
        &__inner {
          &::before {
            opacity: .4;
            transition: opacity var(--default-timing) var(--default-bezier);
          }
        }

        &:hover {
          .link__inner::before {
            opacity: 1;
          }
        }
      }
    }

    &_four {
      .link {
        &__inner::before {
          opacity: 0;
          transition: opacity var(--default-timing) var(--default-bezier);
        }

        &:hover {
          .link__inner::before {
            opacity: 1;
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .ui {
    &_one {
      .link {
        &::before {
          bottom: 3px;
        }

        &__inner {
          padding-bottom: 2px;
        }
      }
    }

    &_two {
      .link {
        &::before {
          bottom: 3px;
        }

        &__inner {
          padding-bottom: 2px;
        }
      }
    }
  }
}