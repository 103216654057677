.pagination {
  .swiper-pagination-bullet {
    --swiper-pagination-bullet-inactive-opacity: 1;
    background-color: var(--color-add-04-40);
    border-radius: 100%;
    transition: width, background-color;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-bezier);
    margin: 0 6px;

    &.swiper-pagination-bullet-active {
      background-color: var(--color-add-04);
      border-radius: 9px;
    }
  }

  &.swiper-pagination-progressbar.swiper-pagination-horizontal {
    height: 1px;
    background: var(--color-grey-02);
    --swiper-pagination-color: var(--turquoise-color);

    .swiper-pagination-progressbar-fill {
      height: 2px;
      top: unset;
      bottom: 0;
    }
  }

  &_custom {
    display: flex;
    align-items: center;
  }

  &__current,
  &__delimiter,
  &__total {
    font-style: normal;
    font-weight: 400;
    line-height: 115%;
    text-transform: uppercase;
    color: var(--color-main-01);
    opacity: 0.4;
  }
}

@include respond-up('s-large') {
  .pagination {
    &__current,
    &__delimiter,
    &__total {
      font-size: 24px;
    }

    &__delimiter,
    &__total {
      margin-left: 5px;
    }
  }
}

@include respond-up('medium') {
  .pagination {
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;

      &.swiper-pagination-bullet-active {
        width: 12px;
      }
    }
  }
}

@include respond-down('medium') {
  .pagination {
    &__current,
    &__delimiter,
    &__total {
      font-size: 20px;
    }

    &__delimiter,
    &__total {
      margin-left: 4px;
    }
  }
}

@include respond-down('small') {
  .pagination {
    .swiper-pagination-bullet {
      width: 4px;
      height: 4px;

      &.swiper-pagination-bullet-active {
        width: 8px;
      }
    }
  }
}