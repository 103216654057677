.about {
  &__main {
    display: flex;
  }

  &__content {
    display: flex;
  }
}

@include respond-up('x-large') {
  .about {
    padding-bottom: 180px;
    &__tabs {
      &.tabs__vertical {
        .tabs {
          &__item {
            &::before {
              bottom: 20px;
            }
          }

          &__text {
            font-size: 24px;
            font-weight: 400;
          }
        }
      }
    }


    &__item {
      &:not(:first-child) {
        margin-top: 130px;
      }
    }
  }
}

@include respond-up('large') {
  .about {
    &__main {
      margin-top: 60px;
    }
  }
}

@include respond-between('s-large', 'large') {
  .about {
    padding-bottom: 120px;

    &__item {
      &:not(:first-child) {
        margin-top: 110px;
      }
    }
  }
}

@include respond-up('s-large') {
  .about {
    &__tabs-wrap {
      position: sticky;
      top: calc(var(--header-height) + 40px);
    }

    &__tabs-container {
      flex: 0 0 calc(var(--grid-column2) + var(--grid-gap));
      padding-right: var(--grid-gap);
    }

    &__content {
      flex: 0 0 var(--grid-column10);
      justify-content: flex-end;
      border-left: 1px solid var(--color-grey-02);
    }

    &__list {
      width: var(--grid-column9);
    }
  }
}

@include respond('s-large') {
  .about {
    &__main {
      margin-top: 50px;
    }
  }
}

@include respond-up('medium') {
  .about {

  }
}

@include respond('medium') {
  .about {
    padding-bottom: 146px;

    &__main {
      margin-top: 40px;
    }

    &__content {
      margin-top: 40px;
    }

    &__item {
      &:not(:first-child) {
        margin-top: 60px;
      }
    }
  }
}

@include respond-down('medium') {
  .about {
    &__list {
      width: 100%;
    }

    &__main {
      flex-direction: column;
    }

    &__tabs-wrap {
      background-color: var(--color-add-04);
      transition: transform .3s linear;
      padding-top: 8px;

      &._sticky {
        position: fixed;
        z-index: 50;
        top: 0;
        padding-left: var(--grid-spacer);
        padding-right: var(--grid-spacer);
        left: 0;
        right: 0;
      }
    }

    &__tabs {
      transition: transform .3s linear;
    }
  }
}

@include respond-down('small') {
  .about {
    padding-bottom: 99px;

    &.index-page__block {
      padding-top: 80px;
    }

    &__main {
      margin-top: 15px;
    }

    &__content {
      margin-top: 30px;
    }

    &__item {
      &:not(:first-child) {
        margin-top: 40px;
      }
    }
  }
}