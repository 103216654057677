.common-price-info {
  border-top: 1px solid var(--color-grey-02);
  padding-top: 20px;

  &__item {
    color: var(--turquoise-color);
  }
}

@include respond-up('medium') {
  .common-price-info {
    &__item {
      &:last-child {
        margin-top: 4px;
      }
    }
  }
}

@include respond-down('small') {
  .common-price-info {
    &__item {
      &:last-child {
        margin-top: 9px;
      }
    }
  }
}