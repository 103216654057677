.custom-cursor {
  display: block;
  height: 66px;
  width: 66px;
  margin-left: -33px;
  margin-top: -33px;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1000100;

  &__arrow {
    display: flex;
    transform: translate3d(-50%, -50%, 0);
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform-origin: center;

    svg {
      transition: transform .2s;
    }
  }

  &__arrow {
    transition: opacity .2s 0s, transform .2s 0s;
    transform: translate3d(-50%, -50%, 0);

    svg {
      path {
        stroke: #fff;
        transition: stroke .2s linear;
      }
    }

    &_prev {

    }
  }

  &._arrows_prev .custom-cursor__arrow_prev,
  &._arrows_next .custom-cursor__arrow_next {
    opacity: 1;
  }

  &._arrows_prev .custom-cursor__arrow_prev {
    opacity: 1;
  }

  &._arrows_prev .custom-cursor__disc,
  &._arrows_next .custom-cursor__disc {
    opacity: 0;
    transform: translate3d(-50%, -50%, 0);
  }

  &._hide {
    display: none;
  }
}

.no-cursor {
  cursor: none!important;
}