h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
  text-transform: uppercase;
  font-stretch: 151%;
  font-variation-settings: 'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 60;
  font-style: normal;
}

h1, .h1, h2, .h2, h3, .h3 {
  font-weight: 600;
  line-height: 120%;
}

h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 500;
  line-height: 155%;
}

@include respond-up('x-large') {
  h1, .h1,
  h3, .h3,
  h4, .h4,
  h5, .h5 {
    line-height: 130%;
  }

  h1, .h1 {
    //--h-size: 85px;
    --h-size: clamp(60px, calc(85/3200*100vw), 85px);
  }

  h2, .h2 {
    //--h-size: 63px;
    --h-size: clamp(40px, calc(63/3200*100vw), 63px);
    line-height: 135%;
  }

  h3, .h3 {
    --h-size: 43px;
  }

  h4, .h4,
  h5, .h5 {
    --h-size: 36px;
  }
}

@include respond-between('s-large', 'large') {
  h1, .h1 {
    --h-size: 60px;
  }

  h2, .h2 {
    --h-size: 40px;
  }

  h3, .h3 {
    --h-size: 30px;
  }

  h4, .h4 {
    --h-size: 24px;
  }

  h5, .h5 {
    --h-size: 18px;
  }
}

@include respond-up("s-large") {
  h6, .h6 {
    --h-size: 16px;
  }
}

@include respond("s-large") {
  h2, .h2 {
    &.h2_laptop {
      --h-size: 32px;
    }
  }

  h3, .h3 {
    &.h3_laptop {
      --h-size: 26px;
    }
  }

  h4, .h4 {
    &.h4_laptop {
      --h-size: 20px;
    }
  }
}

@include respond("medium") {
  h1, .h1 {
    --h-size: 48px;
  }

  h2, .h2 {
    --h-size: 32px;
  }

  h3, .h3 {
    --h-size: 24px;
  }

  h4, .h4 {
    --h-size: 20px;
  }

  h5, .h5 {
    --h-size: 20px;
  }

  h6, .h6 {
    --h-size: 16px;
  }
}

@include respond-down("small") {
  h1, .h1 {
    --h-size: 30px;
  }

  h2, .h2 {
    --h-size: 20px;
  }

  h3, .h3 {
    --h-size: 20px;
  }

  h4, .h4 {
    --h-size: 18px;
  }

  h5, .h5 {
    --h-size: 18px;
  }

  h6, .h6 {
    --h-size: 14px;
  }
}
