.payment {
  &__link {
    display: flex;
    align-items: flex-start;
  }

  &__text-block {
    opacity: 0;
    height: 0;
    transition-property: height, opacity;
    transition-duration: var(--medium-timing);
    transition-timing-function: var(--default-bezier);
    z-index: -100;
  }

  &__text-block,
  &__text-wrap,
  &__text {
    display: block;
  }

  &__item {
    position: relative;
    grid-row-gap: 0;
    border-bottom: 1px solid var(--color-grey-02);
    overflow: hidden;
    align-items: flex-start;

    &:first-child {
      border-top: 1px solid var(--color-grey-02);
    }

    &:before,
    &:after {
      transition: transform var(--medium-timing) var(--default-bezier);
      position: absolute;
      content: '';
      background: var(--color-grey-03);
    }

    &:before {
      right: 0;
      height: 2px;
    }

    &:after {
      width: 2px;
      right: 9px;
    }

    &._opened {
      &:before,
      &:after {
        transform: rotate(45deg);
      }

      .payment {
        &__text-block {
          height: auto;
          opacity: 1;
          z-index: 0;
          pointer-events: none;
        }

        &__text {
          height: inherit;
        }

        &__position {
          color: var(--color-main-01);
        }

        &__link-text {
          color: var(--color-add-05);
        }
      }
    }
  }

  &__position,
  &__link-text {
    transition: color var(--medium-timing) var(--default-bezier);
  }

  &__position-wrap {
    display: flex;
    align-items: center;
  }
}

@include respond-up('x-large') {
  .payment {
    &__item {
      &::before {
        width: 30px;
        top: 57px;
      }

      &::after {
        height: 30px;
        right: 14px;
        top: 43px;
      }
    }

    &__link {
      padding: 34px 0;
    }

    &__list {
      margin-top: 47px;
    }

    &__text-wrap {
      padding-bottom: 58px;
    }
  }
}

@include respond-up('large') {
  .payment {
    &__list {
      width: var(--grid-column11);
    }

    &__text-block {
      width: var(--grid-column4);
    }

    &__link {
      grid-column: 1/12;
      grid-row: 1;
    }
  }
}

@include respond('large') {
  .payment {
    &__list {
      margin-top: 60px;
    }
  }
}

@include respond-between('s-large', 'large') {
  .payment {
    &__item {
      padding: 24px 0;
      &:before {
        top: 37px;
      }

      &:after {
        top: 28px;
      }
    }

    &__link {
      padding: 24px 0;
      margin: -24px 0;
    }
  }
}

@include respond-up('s-large') {
  .payment {
    &__item {
      &._opened {
        padding-bottom: 30px;

        .payment {
          &__link {
            padding-bottom: 30px;
            margin-bottom: -30px;
          }
        }
      }
    }

    &__position-wrap {
      width: var(--grid-column5);
    }

    &__text-block {
      margin-left: var(--grid-gap);
    }
  }
}

@include respond('s-large') {
  .payment {
    &__list {
      margin-top: 50px;
    }

    &__text-block {
      width: var(--grid-column5);
    }

    &__link {
      grid-column: 1/13;
      grid-row: 1;
    }
  }
}

@include respond-between('medium', 'large') {
  .payment {
    &__item {
      &:before {
        width: 20px;
      }

      &:after {
        height: 20px;
        right: 9px;
      }
    }
  }
}

@include respond-up('medium') {
  .payment {
    &__position {
      flex: 0 0 var(--grid-column);
    }

    &__link-text {
      margin-left: var(--grid-gap);
      flex: 0 0 var(--grid-column4);
    }
  }
}

@include respond('medium') {
  .payment {
    &__list {
      margin-top: 40px;
    }

    &__item {
      &:before {
        top: 31px;
      }

      &:after {
        top: 22px;
      }

      &._opened {
        .payment__text-block {
          margin-top: 21px;
        }
      }
    }

    &__link {
      grid-column: 1/7;
    }

    &__position-wrap {
      width: var(--grid-column6);
    }

    &__text-block {
      width: var(--grid-column4);
      transform: translateX(var(--grid-column));
      margin-left: var(--grid-gap);
    }
  }
}

@include respond-down('medium') {
  .payment {
    &__item {
      padding: 20px 0;

      &._opened {
        padding-bottom: 30px;

        .payment {
          &__link {
            padding-bottom: 30px;
            margin-bottom: -30px;
          }
        }
      }
    }

    &__link {
      padding: 20px 0;
      margin: -20px 0;
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .payment {
    &__list {
      margin-top: 35px;
    }

    &__item {
      &:before {
        top: 29px;
        width: 16px;
      }

      &:after {
        top: 22px;
        height: 16px;
        right: 7px;
      }

      &._opened {
        .payment__text-block {
          margin-top: 20px;
        }
      }
    }

    &__link {
      grid-column: 1/5;
    }

    &__text-block {
      width: var(--grid-column3);
    }

    &__link-text {
      margin-left: 10px;
    }
  }
}