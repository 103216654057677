.plannings {
  &._plannings-card {
    background: var(--color-grey-01);
  }

  &__view-type {
    &.grid-block {
      grid-row-gap: 0;
    }
  }
}

@include respond-up('x-large') {
  .plannings {
    padding-bottom: 180px;
  }
}

@include respond-up('large') {
  .plannings {
    --padding-bottom: 100px;
    &__view-type {
      &._filter-side,
      &._filter-side-alt {
        margin-top: 60px;
      }

      &._filter-top {
        margin-top: 40px;
        .plannings {
          &__plannings-list-wrap {
            margin-top: 43px;
            padding-top: 40px;
          }
        }
      }
    }
  }
}

@include respond-between('s-large', 'large') {
  .plannings {
    padding-bottom: 120px;
  }
}

@include respond-up('s-large') {
  .plannings {
    &._plannings-card {
      padding: 60px var(--grid-column) var(--padding-bottom);
    }

    &._filter-top {
      .plannings {
        &__filter-wrap {
          grid-column: 1/13;
          grid-row: 1;
        }

        &__plannings-list-wrap {
          grid-column: 1/13;
          grid-row: 2;
          position: relative;
          &:before {
            position: absolute;
            content: '';
            height: 1px;
            width: 100vw;
            top: 0;
            left: calc(0px - var(--grid-spacer-and-indent));
            background: var(--color-grey-02);
          }
        }
      }
    }

    &._filter-side,
    &._filter-side-alt {
      .plannings {
        &__filter-wrap {
          grid-column: 1/4;
        }

        &__plannings-list-wrap {
          grid-column: 4/13;
        }
      }
    }
  }
}

@include respond('s-large') {
  .plannings {
    --padding-bottom: 90px;
    &__view-type {
      &._filter-side,
      &._filter-side-alt,
      &._filter-top {
        margin-top: 50px;
      }

      &._filter-top {
        .plannings {
          &__plannings-list-wrap {
            margin-top: 33px;
            padding-top: 30px;
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .plannings {

  }
}

@include respond('medium') {
  .plannings {
    padding-bottom: 116px;
    &__view-type {
      &._filter-side,
      &._filter-side-alt,
      &._filter-top {
        margin-top: 40px;
      }

      &._filter-top,
      &._filter-side {
        .plannings {
          &__plannings-list-wrap {
            margin-top: 30px;
            padding-top: 20px;
          }
        }
      }

      &._filter-side-alt {
        .plannings {
          &__plannings-list-wrap {
            margin-top: 50px;
          }
        }
      }
    }

    &__filter-wrap {
      grid-column: 1/7;
      grid-row: 1;
    }

    &__plannings-list-wrap {
      grid-column: 1/7;
      grid-row: 2;
    }

    &._plannings-card {
      padding: 40px 50px 80px;
    }
  }
}

@include respond-down('medium') {
  .plannings {
    &__view-type {
      &._filter-top,
      &._filter-side {
        .plannings {
          &__plannings-list-wrap {
            position: relative;
            &:before {
              position: absolute;
              content: '';
              height: 1px;
              width: 100vw;
              top: 0;
              left: calc(0px - var(--grid-spacer-and-indent));
              background: var(--color-grey-02);
            }
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .plannings {
    padding-top: 30px;
    padding-bottom: 80px;

    &__view-type {
      &._filter-side,
      &._filter-side-alt,
      &._filter-top {
        margin-top: 25px;
      }

      &._filter-top,
      &._filter-side {
        .plannings {
          &__plannings-list-wrap {
            margin-top: 30px;
            padding-top: 20px;
          }
        }
      }

      &._filter-side-alt {
        .plannings {
          &__plannings-list-wrap {
            margin-top: 40px;
          }
        }
      }
    }

    &__filter-wrap {
      grid-column: 1/5;
      grid-row: 1;
    }

    &__plannings-list-wrap {
      grid-column: 1/5;
      grid-row: 2;
    }

    &._plannings-card {
      padding: 60px 20px;
      margin: 0 calc(0px - var(--grid-spacer-and-indent));
    }
  }
}