.custom-select {
  margin-top: 6px;

  &_sort {
    margin-top: 0;
  }

  &._open {
    .custom-select {
      &__content-wrap {
        opacity: 1;
        pointer-events: auto;
        z-index: 101;
      }
    }
  }

  &__field {
    border-radius: 0;
    border: none;
    background: var(--t);
    width: 100%;
    padding: 0;
    cursor: pointer;
  }

  &__field-wrapper {
    background: var(--filter-select-wrap-bg);
    border: 1px solid var(--filter-select-border);
    border-radius: var(--filter-select-radius);
    position: relative;
    z-index: 100;

    &_sort {
      background: var(--t);
      border: unset;
      border-radius: unset;
    }
  }

  &__arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;

    svg {
      path {
        stroke: var(--color-main-01);
      }
    }

    &_sort {
      svg {
        path {
          stroke: var(--color-add-05);
        }
      }
    }
  }

  &__inner {
    position: relative;
  }

  &__content-wrap {
    position: absolute;
    top: calc(100% - var(--filter-select-top));
    left: 0;
    right: 0;
    background: var(--color-add-04);
    opacity: 0;
    transition: opacity var(--default-timing) var(--default-bezier);
    padding-top: var(--filter-select-top);
    filter: drop-shadow(0px 1px 15px rgba(0, 0, 0, 0.07));
    border-radius: var(--filter-select-options-radius);
    pointer-events: none;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: var(--t);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-grey-02);
    }

    &_sort {
      padding-top: 0;
      top: 100%;
    }
  }

  &__choice-icon {
    opacity: 0;
    transition: opacity var(--default-timing) var(--default-bezier);
  }

  &__choice-name {
    transition: color var(--default-timing) var(--default-bezier);
  }

  &__input {
    display: none;

    &:checked + .custom-select__label {
      background: var(--color-grey-01);

      .custom-select {
        &__choice-icon {
          opacity: 1;
        }

        &__choice-name {
          color: var(--color-add-05);
        }
      }
    }
  }

  &__item {
    &:last-child {
      .custom-select__label {
        border-radius: var(--filter-select-options-radius);
      }
    }
  }

  &__label,
  &__no-choices {
    width: 100%;
    padding: 8px 10px;
    transition: background var(--default-timing) var(--default-bezier);
    display: flex;
  }
}

@include respond-up('x-large') {
  .custom-select {
    &__field-wrapper {
      &_sort {
        width: 355px;
      }
    }
  }
}

@include respond-between('s-large', 'large') {
  .custom-select {
    &__field-wrapper {
      &_sort {
        width: 255px;
      }
    }
  }
}

@include respond-up('s-large') {
  .custom-select {
    &__field {
      padding: 13px 16px 12px;

      &_sort {
        margin: -13px -16px -12px;
      }
    }

    &__arrow-icon {
      right: 16px;

      &_sort {
        margin-left: 6px;
      }
    }

    &__label {
      &:hover {
        background: var(--color-grey-01);

        .custom-select {
          &__choice-name {
            color: var(--color-add-05);
          }
        }
      }
    }

    &__choice-name {
      margin-left: 10px;
    }

    &__content-wrap {
      max-height: 152px;
    }
  }
}

@include respond('s-large') {
  .custom-select {

  }
}

@include respond-up('medium') {
  .custom-select {

  }
}

@include respond('medium') {
  .custom-select {
    &__field-wrapper {
      &_sort {
        width: 255px;
      }
    }
  }
}

@include respond-down('medium') {
  .custom-select {
    &__field {
      padding: 12px 14px 11px;

      &_sort {
        margin: -12px -14px -11px;
      }
    }

    &__arrow-icon {
      right: 14px;

      &_sort {
        margin-left: 4px;
      }
    }

    &__choice-name {
      margin-left: 7px;
    }

    &__content-wrap {
      max-height: 136px;
    }
  }
}

@include respond-down('small') {
  .custom-select {
    &__field-wrapper {
      &_sort {
        width: 225px;
      }
    }
  }
}