.advantages-accordion {
  &__inner {
    border-top: 1px solid #C5C5C5;
    display: flex;
  }

  &__picture-wrap {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      mix-blend-mode: multiply;
      background: linear-gradient(180deg, rgba(40, 69, 104, 0) 0%, #2F415B 100%);
    }
  }

  &__item {
    &._active {
      .advantages-accordion {
        &__link {
          color: var(--color-main-01);
        }
      }
    }
  }

  &__link {
    color: #7A7A7A;
    transition: color var(--default-timing) var(--default-bezier);
    display: flex;
    align-items: center;
    position: relative;

    &::after {
      content: '';
      border-color: var(--color-main-01);
      transition: transform var(--default-timing) var(--default-bezier);
    }
  }

  &__link-title {
    display: flex;
  }

  &__text-wrap {
    position: absolute;
  }

  &__title,
  &__description {
    color: #fff;
  }

  &__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('x-large') {
  .advantages-accordion {
    &__picture {
      height: calc((var(--grid-column9) + var(--grid-spacer-and-indent)) * 1298/2263);
    }

    &__link {
      padding: 30px 0;
    }

    &__description {
      margin-top: 30px;
    }

    &__text-wrap {
      left: 100px;
      bottom: 100px;

      width: calc(var(--grid-column5) + var(--grid-gap));
    }
  }
}

@include respond-between('s-large', 'large') {
  .advantages-accordion {
    &__picture {
      height: calc((var(--grid-column9) + var(--grid-spacer-and-indent)) * 713/1153);
    }

    &__link {
      padding: 20px 0;
    }

    &__text-wrap {
      left: 60px;
      bottom: 60px;

      width: calc(var(--grid-column6) + var(--grid-gap) - 60px);
    }
  }
}

@include respond-up('s-large') {
  .advantages-accordion {
    &__inner {
      overflow: hidden;
    }

    &__text-list {
      flex: 0 0 calc(var(--grid-spacer-and-indent) + var(--grid-column3) + var(--grid-gap));
      padding-top: 60px;
      padding-left: var(--grid-spacer-and-indent);
      margin-top: -20px;
    }

    &__images {
      &.swiper {
        width: calc(var(--grid-column9) + var(--grid-spacer-and-indent));
      }
    }

    &__item {
      &._active {
        .advantages-accordion {
          &__link {
            &::after {
              transform: scale3d(1, 1, 1);
            }
          }
        }
      }
    }

    &__link {
      column-gap: 35px;

      &::after {
        border-bottom: 3px solid;
        flex: auto;
        transform: scale3d(0, 1, 1);
        transform-origin: right;
      }
    }

    &__pagination {
      display: none;
    }

    &__picture-wrap {
      &::before {
        height: 61%;
      }
    }
  }
}

@include respond-between('medium', 'large') {
  .advantages-accordion {
    &__description {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .advantages-accordion {
    &__link {
      padding: 20px 10px;

      &::after {
        height: 14px;
      }
    }

    &__picture-wrap {
      &::before {
        height: 68%;
      }
    }

    &__picture {
      height: calc(100vw * 685/768);
    }

    &__text-wrap {
      left: var(--grid-spacer-and-indent);
      right: 55px;
      bottom: 72px;
    }

    &__pagination {
      &.swiper-pagination {
        &.swiper-pagination-bullets {
          bottom: 36px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .advantages-accordion {
    overflow: hidden;
    &__inner {
      flex-direction: column;
    }

    &__text-list {
      display: flex;
      margin: 0 -10px;
      padding: 0 var(--grid-spacer-and-indent);
      overflow: scroll hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      flex: 0 0 auto;
      &._active {
        .advantages-accordion {
          &__link {
            &::after {
              transform: translate3d(-50%, 0, 0) scale3d(1, 1, 1);
            }
          }
        }
      }
    }

    &__link {
      &::after {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate3d(-50%, 0, 0) scale3d(1, 0, 1);
        border-left: 3px solid;
        transform-origin: bottom;
      }
    }

    &__link-title {
      text-wrap: nowrap;
    }
  }
}

@include respond-down('small') {
  .advantages-accordion {
    &__link {
      padding: 13px 10px;

      &::after {
        height: 10px;
      }
    }

    &__picture-wrap {
      &::before {
        top: 0;
      }
    }

    &__picture {
      height: calc(100vw * 514/375);
    }

    &__text-wrap {
      left: var(--grid-spacer-and-indent);
      right: var(--grid-spacer-and-indent);
      bottom: 38px;
    }

    &__pagination {
      &.swiper-pagination {
        &.swiper-pagination-bullets {
          bottom: 14px;
        }
      }
    }

    &__description {
      margin-top: 10px;
    }
  }
}