.advantages {

}

@include respond-up('large') {
  .advantages {
    &__main {
      margin-top: 60px;
    }
  }
}

@include respond('s-large') {
  .advantages {
    &__main {
      margin-top: 50px;
    }
  }
}

@include respond-up('medium') {
  .advantages {

  }
}

@include respond('medium') {
  .advantages {
    &__main {
      margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .advantages {

  }
}

@include respond-down('small') {
  .advantages {
    &.index-page__block {
      padding-top: 80px;
    }

    &__main {
      margin-top: 25px;
    }
  }
}