.hero-info {
  &__address-wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }

  &__inner {
    &.grid-block {
      grid-row-gap: 0;
    }
  }

  &__preview-wrap {
    position: relative;
    display: flex;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__video-link-wrap {
    display: flex;
  }

  &__video-link {
    display: flex;
    height: max-content;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__address {
    display: flex;
    align-items: center;
  }

  &__address-icon {
    display: flex;

    svg path {
      stroke: var(--color-main-01);
    }
  }

  &__address-title {
    margin-left: 9px;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid var(--color-grey-02);

    &:first-child {
      border-top: 1px solid var(--color-grey-02);
    }
  }

  &__item-key {
    font-style: normal;
    font-weight: 400;
    color: var(--color-grey-03);
  }

  &__item-value {
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
    text-transform: uppercase;
    color: var(--color-add-05);
  }

  &__video-link-icon {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &__addresses-list {
    display: flex;
    flex-direction: column;
  }
}

@include respond-up('x-large') {
  .hero-info {
    padding-bottom: 180px;
    &__address-icon {
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@include respond-up('x-large') {
  .hero-info {
    &__item-key,
    &__item-value {
      font-size: 26px;
    }
  }
}

@include respond-up('large') {
  .hero-info {

    &__inner {
      padding-top: 80px;
    }

    &__list,
    &__logo {
      margin-top: 60px;
    }
  }
}

@include respond-between('s-large', 'large') {
  .hero-info {
    padding-bottom: 120px;

    &__item-key,
    &__item-value {
      font-size: 20px;
    }
  }
}

@include respond-up('s-large') {
  .hero-info {
    &__address-wrapper {
      grid-row-gap: 30px;
    }

    &__address-wrapper,
    &__list {
      grid-column: 5/12;
    }

    &__video-link-wrap {
      grid-column: 1/4;
      grid-row: 1/4;
    }

    &__title {
      grid-column: 1/4;
      grid-row: 1/2;
    }

    &__logo {
      grid-column: 1/2;
      display: flex;
      height: fit-content;
    }

    &__preview-wrap {
      height: calc(var(--grid-column3) * (190 / 338));
    }

    &__video-title {
      margin-top: 14px;
    }

    &__item {
      padding: 20px 0;
    }

    &__item-key {
      flex: 0 0 var(--grid-column3);
    }

    &__item-value {
      flex: 0 0 var(--grid-column4);
    }

    &__preview-wrap,
    &__video-link {
      width: 100%;
    }
  }
}

@include respond('s-large') {
  .hero-info {
    &__inner {
      padding-top: 60px;
    }

    &__list,
    &__logo {
      margin-top: 50px;
    }
  }
}

@include respond-up('medium') {
  .hero-info {
    &__item-key {
      text-transform: uppercase;
      line-height: 155%;
    }

    &__item-value {
      margin-left: var(--grid-gap);
    }
  }
}

@include respond('medium') {
  .hero-info {
    &__inner {
      padding-top: 50px;
    }

    &__list,
    &__title,
    &__address-wrapper {
      grid-column: 1/6;
    }

    &__video-title {
      margin-top: 16px;
    }

    &__item {
      padding: 18px 0;
    }

    &__item-key {
      font-size: 18px;
      flex: var(--grid-column2);
    }

    &__item-value {
      font-size: 18px;
      flex: var(--grid-column3);
    }

    .pattern-block__pattern-wrap {
      margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .hero-info {
    &__address-wrapper {
      grid-row-gap: 20px;
    }

    &__list,
    &__video-link {
      margin-top: 40px;
    }

    &__logo {
      display: none;
    }

    .pattern-block__pattern-wrap {
      position: static;
    }
  }
}

@include respond-down('small') {
  .hero-info {
    &__inner {
      padding-top: 30px;
    }

    &__description {
      margin-top: 15px;
    }

    &__list,
    &__video-link {
      margin-top: 30px;
    }

    &__title,
    &__address-wrapper,
    &__list,
    &__video-link-wrap {
      grid-column: 1/5;
    }

    &__video-title {
      margin-top: 10px;
    }

    &__item {
      padding: 8px 0;
      flex-direction: column;
    }

    &__item-key {
      font-size: 13px;
      line-height: 150%;
    }

    &__item-value {
      font-size: 16px;
      margin-top: 3px;
    }

    &__address-title {
      font-size: 17px;
    }

    .pattern-block__pattern-wrap {
      margin-top: 30px;
    }
  }
}