%text-body {
  font-family: var(--font);
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

@include respond-up('x-large') {
  %text-body {
    --font-size: 24px;
  }
}

@include respond-between("medium", "large") {
  %text-body {
    --font-size: 18px;
  }
}

@include respond-down("small") {
  %text-body {
    --font-size: 16px;
  }
}