%text-main {
  font-family: var(--font);
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

@include respond-up('x-large') {
  %text-main {
    --font-size: 36px;
    line-height: 155%;
  }
}

@include respond-between("medium", "large") {
  %text-main {
    --font-size: 24px;
  }
}

@include respond-down("small") {
  %text-main {
    --font-size: 18px;
  }
}