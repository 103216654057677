.pipeline {
  &__link {
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      display: none;
    }
  }

  &__link-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__link-count {
    display: flex;
    align-items: center;
  }

  &__link-count-icon {
    display: flex;
  }

  &__link-count-title {
    margin-left: 3px;
    padding-top: 2px;
  }

  &__nav {
    display: flex;
    align-items: flex-end;
    position: relative;
    justify-content: flex-end;
  }

  &__pagination {
    &.swiper-pagination-progressbar {
      position: relative;
    }
  }

  &__arrows {
    margin-left: 20px;
  }

  &__picture-wrap {
    position: relative;
    display: flex;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(58, 68, 73, 0) 7.64%, #3A4449 98.2%);
      mix-blend-mode: multiply;
      opacity: 0.9;
    }
  }

  &__picture-icon {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 100%;
    border: 2px solid var(--color-add-04-40);

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      transform: translate3d(-50%, -50%, 0);
      background: var(--color-add-04);
    }

    &::after {
      transform: translate3d(-50%, -50%, 0) rotate(90deg);
    }
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('x-large') {
  .pipeline {
    &__link-bottom {
      margin-top: 16px;
    }

    &__picture-wrap {
      height: calc(var(--grid-column4) * (613 / 865));
    }

    &__picture-icon {
      width: 70px;
      height: 70px;

      &::before,
      &::after {
        width: 26px;
      }
    }
  }
}

@include respond-up('large') {
  .pipeline {
    &__main {
      margin-top: 60px;
    }

    &__nav {
      margin-top: 30px;
    }
  }
}

@include respond-between('s-large', 'large') {
  .pipeline {
    &__link-bottom {
      margin-top: 12px;
    }

    &__picture-wrap {
      height: calc(var(--grid-column4) * (326 / 460));
      max-height: 326px;
    }

    &__picture-icon {
      width: 52px;
      height: 52px;

      &::before,
      &::after {
        width: 20px;
      }
    }
  }
}

@include respond-up('s-large') {
  .pipeline {
    &__picture-wrap {
      &::before {
        opacity: 0;
        transition: opacity var(--default-timing) var(--default-bezier);
        will-change: opacity;
      }
    }

    &__picture-icon {
      opacity: 0;
      transition: opacity var(--default-timing) var(--default-bezier);
      will-change: opacity;
    }

    &__link {
      &:hover {
        .pipeline {
          &__picture-icon {
            opacity: 1;
          }

          &__picture-wrap {
            &::before {
              opacity: 0.9;
            }
          }
        }
      }
    }
  }
}

@include respond('s-large') {
  .pipeline {
    &__main {
      margin-top: 50px;
    }

    &__nav {
      margin-top: 24px;
    }
  }
}

@include respond-up('medium') {
  .pipeline {

  }
}

@include respond('medium') {
  .pipeline {
    &__main {
      margin-top: 40px;
    }

    &__picture-wrap {
      height: calc(var(--grid-column3) * (237 / 335));
    }
  }
}

@include respond-down('medium') {
  .pipeline {
    &__link-bottom {
      margin-top: 10px;
    }

    &__nav {
      margin-top: 20px;
    }

    &__picture-icon {
      width: 42px;
      height: 42px;

      &::before,
      &::after {
        width: 16px;
      }
    }
  }
}

@include respond-down('small') {
  .pipeline {
    &__main {
      margin-top: 23px;
    }

    &__picture-wrap {
      height: calc(var(--grid-column4) * (237 / 335));
    }
  }
}